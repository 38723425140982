
// Create a Leaflet map
const map = L.map('map').setView([53.37766, 14.62181], 18);

// Add OpenStreetMap tiles
const osmTiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    maxZoom: 18,
    attribution: '&copy; <a href="http://www.openstreetmap.org/copyright" target="_blank" title="OpenStreetMap">OpenStreetMap</a>'
});

// Add Google Maps tiles
const googleMapsTiles = L.tileLayer('https://mt1.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
    maxZoom: 18,
    attribution: '&copy; 2023 <a href="https://www.google.com/intl/pl_PL/help/terms_maps/" target="_blank" title="Google Maps">Google Maps</a>'
});

// Layer group to switch map views
const baseMaps = {
    'OpenStreetMap': osmTiles,
    'Google Maps': googleMapsTiles
};

// Default to setting OpenStreetMap as the active layer
osmTiles.addTo(map);

// Add a marker for the school
const schoolMarker = L.marker([53.37766, 14.62181])
    .addTo(map)
    .bindPopup('<strong>Centrum Edukacji Zdroje</strong>').openPopup();

// Add a circular area (school premises)
const schoolArea = L.circle([53.37766, 14.62181], {
    color: '#2B82CB', 
    fillColor: '#2B82CB',
    fillOpacity: 0.5, 
    radius: 25 
}).addTo(map);

// Add a layer control to change map views
L.control.layers(baseMaps).addTo(map);
