
// Cookie notice
const cookieContainer   = document.querySelector('.cookie-notice');
const cookieButton      = document.querySelector('.js-accept-cookie-button');

cookieButton.addEventListener('click', () => {
    cookieContainer.classList.remove('active');
    localStorage.setItem('cookieBannerDisplayed', 'true');
});

setTimeout(() => {
    if(!localStorage.getItem('cookieBannerDisplayed')) {
        cookieContainer.classList.add('active');
    }
}, 2000);
